import {React } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { Users } from './pages/UserPage';
import Aibn from './pages/Aibn';
import Bpositive from './pages/Bpositive';
import Maheshwari from './pages/Maheshwari';
import Rmb from './pages/Rmb';
import Bizoprivacy from './pages/Bizoprivacy';
import Visitor from './pages/Visitor';
import BulkUpload from './pages/BulkUpload';
import Konnectprivacy from './pages/Konnectprivacy';
import Cognisun360privacy from './pages/Cognisunprivacy';
import Nirmaprivacy from './pages/Nirmaprivacy';
import Comfortprivacy from './pages/Comfortprivacy';



export default function App() {
 return (
    <div>
       {/* <Helmet>
        {/* <title>{`Tirth's VCard`}</title>
        <meta name="title"  content={`Tirth's VCard`} />
        <meta name="description"  content={`Director`} /> */}
        {/* <meta name="roots"  content={data.profilepicture ? profilePicture : userPlaceholder} /> */}
        {/* <meta property="og:title" content={`${data.firstname} ${data.lastname}'s VCard`} />
        <meta property="og:description" content={`${data.occupation} ${data.businessname}`} />
        <meta property="og:roots" content={data.profilepicture ? profilePicture : userPlaceholder} /> */}
        {/* <meta property="og:url" content={window.location.href} /> */}
        {/* <meta property="og:type" content="profile" />
      </Helmet> */}
      <div>
        <Routes>
          <Route path="*" element={<HomePage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/:userId" element={<Users />} />
          <Route path="/aibn" element={<Aibn />} />
          <Route path="/businesspositive" element={<Bpositive />} />
          <Route path="/meconnect" element={<Maheshwari />} />
          <Route path="/rmb" element={<Rmb />} />
          <Route path="/visitor" element={<Visitor/>} />
          <Route path="/bulkimport" element={<BulkUpload/>} />
          <Route path="/networking-app-policy" element={<Bizoprivacy />} />
          <Route path="/konnectprivacy" element={<Konnectprivacy/>} />
          <Route path="/cognisunprivacy" element={<Cognisun360privacy />} />
          <Route path="/nirmaprivacy" element={<Nirmaprivacy />} />
          <Route path="/comfortprivacy" element={<Comfortprivacy />} />
        </Routes>
      </div>
    </div>
  );
}
